<template>
    <div>
        <div v-if="!loading">
            <div class="font-bold text-3xl mb-5 w-full flex justify-end">Zeit <i @click="changeTimeOrder" :class="` cursor-pointer ml-2 fas fa-caret-${caret()}`"></i></div>
            <div v-if="searchedTickets.length > 0">
                <router-link v-for="ticket in searchedTickets" :key="ticket.id" :to="`/qa/${ticket.id}`" :class="`w-100 ticket hover:bg-blue-100 transition-colors cursor-pointer flex justify-between ${ticket.viewed ? 'bg-gray-300' : 'bg-gray-50'} shadow relative mb-3 py-4 px-10`">
                    <span class="w-2 h-full absolute top-0 left-0`"></span>
                    <p class="text-2xl">
                        <span class="max mr-20 inline-block" style="min-width: 80rem">
                            {{ ticket.subject }}
                        </span>
                        <span class="max">
                            {{ ticket.author }}
                        </span>
                    </p>
                    <p class="flex">
                        <span class="date text-2xl text-gray-400">{{ ticket.created_at.split('T')[0] }}</span>
                    </p>
                </router-link>
            </div>
            <div v-else>
                <span class="text-5xl font-semibold flex justify-center">Keine Tickets gefunden, suchen Sie nach etwas anderem</span>
            </div>
        </div>
        <div class="tickets-list relative" v-if="loading">
            <Loading class="absolute top-1/2 left-1/2 transform-translate-x-2/4"></Loading>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        data() {
            return {
                desc: true,
            }
        },
        mounted() {
            const filter = this.$route.query.filter;
            this.getSearchedTickets(encodeURI(filter));
        },
        methods: {
            ...mapActions(['getSearchedTickets']),
            changeTimeOrder() {
                this.desc = !this.desc;
                this.searchedTickets.sort((a, b) => {
                    const dateA = new Date(a.created_at)
                    const dateB = new Date(b.created_at);
                    if (!this.desc) {
                        return dateA - dateB; // Asc order
                    } else {
                        return dateB - dateA; // Desc order
                    }
                })
            },
            caret() {
                if(this.desc) {
                    return 'down';
                } else {
                    return 'up'
                }
            },
        },
        computed: {
            ...mapGetters(['searchedTickets', 'loading'])
        },
        watch: {
            $route(to, from) {
                if(from.query.filter != to.query.filter) {
                    this.getSearchedTickets(encodeURI(to.query.filter));
                }
            }
        }
    }
</script>